// project import
import Routes from "routes";
import ThemeCustomization from "themes";
import Locales from "components/Locales";

const App = () => {
  return (
    <ThemeCustomization>
      <Locales>
        <Routes />
      </Locales>
    </ThemeCustomization>
  );
};

export default App;
