import { lazy } from "react";
import { useRoutes } from "react-router-dom";

// project import
import CommonLayout from "layout/CommonLayout";
import Loadable from "components/Loadable";

// render - landing page
const PagesLanding = Loadable(lazy(() => import("pages/landing")));
const Contact = Loadable(lazy(() => import("pages/contact")));
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    {
      path: "/",
      element: <CommonLayout layout="landing" />,
      children: [
        {
          path: "/",
          element: <PagesLanding />,
        },
        {
          path: "/contact",
          element: <Contact />,
        },
      ],
    },
  ]);
}
